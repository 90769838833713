<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="Name">
                        <span class="required">*</span> Chave:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="product.key"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="Email">
                        <span class="required">*</span> Código:
                      </label>
                      <input
                        autocomplete="new-password"
                        class="form-control"
                        id="Email"
                        placeholder=""
                        type="text"
                        v-model="product.code"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="Email"> Código ERP: </label>
                      <input
                        autocomplete="new-password"
                        class="form-control"
                        id="Email"
                        placeholder=""
                        type="text"
                        v-model="product.erp_code"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label for="Name">
                        <span class="required">*</span> Descrição:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        class="form-control"
                        v-model="product.description"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Tipo:</label>
                      <v-select
                        :options="tipos"
                        :reduce="(tipos) => tipos.title"
                        :label="'title'"
                        v-model="product.type"
                        class="vselect"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Dimensão:</label>
                      <v-select
                        :options="dimensao"
                        :reduce="(dimensao) => dimensao.title"
                        :label="'title'"
                        v-model="product.dimension"
                        class="vselect"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Grão:</label>
                      <v-select
                        :options="grao"
                        :reduce="(grao) => grao.title"
                        :label="'title'"
                        v-model="product.grain"
                        class="vselect"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Furos:</label>
                      <input
                        autocomplete="new-password"
                        class="form-control"
                        type="text"
                        v-model="product.holes"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Composição:</label>
                      <select
                        class="form-control"
                        v-model="product.mixed_or_pure"
                      >
                        <option value=""></option>
                        <option value="PURA">PURA</option>
                        <option value="MISTA">MISTA</option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label for="Cellphone">Quantidade Padrão:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="product.quantity_default"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label for="Cellphone">Estoque Mínimo:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="product.minimum_stock"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="Cellphone"
                        ><span class="required">*</span> Empresa:</label
                      >
                      <v-select
                        :options="companies"
                        :reduce="(companies) => companies.id"
                        :label="'company_name'"
                        v-model="product.company_id"
                        class="vselect"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="Cellphone">Prioridade:</label>
                      <v-select
                        :options="['A', 'B', 'C']"
                        v-model="product.priority"
                        class="vselect"
                      />
                    </div>
                  </div>

                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong>Campos obrigatórios</strong>
                  </span>
                  <div class="block text-right">
                    <router-link to="/products" class="btn btn-back">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <a class="btn btn-add ml-1" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      product: {},
      endereco: {},
      naoLocalizado: false,
      tipos: [
        "DISCO",
        "DISCO BAM18",
        "DISCO PS30",
        "DISCO SA331",
        "MINI",
        "MINI H.AMARELA",
        "MINI H.LONGA",
        "RODA",
      ],
      dimensao: [
        "100 x 25",
        "100 x 40",
        "150 x 25",
        "150 x 30",
        "150 x 38",
        "150 x 50",
        "150 x 75",
        "200 x 25",
        "200 x 38",
        "200 x 50",
        "200 x 75",
        "250 x 50",
        "250 x 75",
        "300 x 25",
        "300 x 50",
        "300 x 100",
        "350 x 50",
        "400 x 50",
        "400 x 100",
        "20 x 20",
        "25 x 20",
        "30 x 20",
        "40 x 20",
        "40 x 25",
        "50 x 20",
        "60 x 20",
        "70 x 25",
        "75 x 20",
        "75 x 25",
      ],
      grao: [
        "P16",
        "P24",
        "P36",
        "P40",
        "P50",
        "P60",
        "P80",
        "P100",
        "P120",
        "P15",
        "P180",
        "P220",
        "P240",
        "P280",
        "P320",
        "P400",
        "P500",
        "P600",
        "P800",
        "P1000",
        "P1200",
        "P1500",
        "P2000",
      ],
      companies: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "products";

      if (self.product.id) {
        api += "/" + self.product.id;

        self.product._method = "PUT";
      }

      axios
        .post(api, self.product)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/products");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "products/" + id;

      axios
        .get(api)
        .then((response) => {
          self.product = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCompanies: function (id) {
      const self = this;
      const api = self.$store.state.api + "companies?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.companies = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTypes: function (id) {
      const self = this;
      const api = self.$store.state.api + "types?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getDimensions: function (id) {
      const self = this;
      const api = self.$store.state.api + "dimensions?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.dimensao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getGrains: function (id) {
      const self = this;
      const api = self.$store.state.api + "grains?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.grao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }

    self.getCompanies();
    self.getTypes();
    self.getGrains();
    self.getDimensions();
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped></style>
