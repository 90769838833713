<template>
  <div>
    <div class="row justify-content-center">
      <div
        class="col-md-3 button-operation input"
        :class="movement.operation == 1 ? 'input-active' : ''"
        @click="(movement.operation = 1), focus()"
      >
        Entrada
      </div>
      <div
        class="col-md-3 button-operation output"
        :class="movement.operation == 2 ? 'output-active' : ''"
        @click="(movement.operation = 2), focus()"
      >
        Saída
      </div>
    </div>

    <div class="row justify-content-center mt-2">
      <div class="col-md-4">
        <label>Código de Barras</label>
        <input
          type="text"
          id="product_code"
          class="form-control"
          @keyup.enter="getProduct"
          v-model="movement.product_code"
          :disabled="movement.operation == 0"
        />
      </div>
      <div class="col-md-2" style="margin-top: 27px">
        <button class="btn btn-back" @click="getProduct">Movimentar</button>
      </div>
    </div>

    <!-- Modal Produtos -->
    <div
      class="modal fade"
      id="modalProducts"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalProductsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalProductsLabel">
              Escolha o produto de um estoque
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Empresa</th>
                    <th scope="col">Código</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Estoque Mínimo</th>
                    <th scope="col">Estoque Atual</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in products" :key="product.id">
                    <th scope="row">{{ product.id }}</th>
                    <td>{{ product.company.company_name }}</td>
                    <td>{{ product.code }}</td>
                    <td>{{ product.description }}</td>
                    <td>{{ product.minimum_stock }}</td>
                    <td>{{ product.stock }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-primary"
                        @click="
                          move(), (movement.company_id = product.company_id)
                        "
                      >
                        Selecionar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      movement: {
        operation: 0,
        product_code: "",
        company_id: null,
      },
      products: [],
    };
  },
  components: {},
  methods: {
    getProduct() {
      const self = this;
      const api = self.$store.state.api + "product/get-stock";

      axios
        .post(api, self.movement)
        .then((response) => {
          self.products = response.data;
          $("#modalProducts").modal("show");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    focus() {
      setTimeout(() => {
        document.getElementById("product_code").focus();
      }, 400);
    },
    move() {
      const self = this;
      const api = self.$store.state.api + "product/stock-movement";

      setTimeout(() => {
        axios
          .post(api, self.movement)
          .then((response) => {
            self.$message("Sucesso", "Movimentação inserida", "success");
            self.movement = {
              product_code: "",
              operation: self.movement.operation,
            };
            self.focus();
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
            self.movement = {
              product_code: "",
              operation: self.movement.operation,
            };
            self.focus();
          });
      }, 500);
    },
  },
};
</script>

<style scoped>
.button-operation {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border: 2px #000000 solid;
  cursor: pointer;
  border-radius: 5px;
  font-size: 22px;
}

.output {
  background: rgba(156, 9, 9, 0.658);
  color: #fff;
  border-color: rgb(156, 9, 9);
}

.output-active {
  background: rgb(156, 9, 9);
  color: #fff;
  border-color: rgb(156, 9, 9);
  font-weight: bold;
}

.input {
  background: rgba(12, 99, 0, 0.658);
  color: #fff;
  border-color: rgb(12, 99, 0);
}

.input-active {
  background: rgb(12, 99, 0);
  color: #fff;
  border-color: rgb(12, 99, 0);
  font-weight: bold;
}
</style>
