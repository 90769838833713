<template>
  <div>
    <div class="card">
      <div class="card-header card-title">
        Painel de Produtos com Estoque Baixo
      </div>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-4">
            <label>Filtrar empresa</label>
            <v-select
              :options="companies"
              :reduce="(companies) => companies.id"
              :label="'company_name'"
              v-model="filters.company_id"
              class="vselect"
              v-on:input="getProduct"
            />
          </div>
          <div class="col-md-3">
            <label>Filtrar por Descrição</label>
            <input
              type="text"
              v-model="filters.description"
              v-on:input="debounce"
              class="form-control"
            />
          </div>
          <div class="col-md-2">
            <label>Filtrar por Código</label>
            <input
              type="text"
              v-model="filters.code"
              v-on:input="debounce"
              class="form-control"
            />
          </div>
          <div class="col-md-2">
            <label>Filtrar por Grão</label>
            <input
              type="text"
              v-model="filters.grain"
              v-on:input="debounce"
              class="form-control"
            />
          </div>
          <div class="col-md-1">
            <label>Prioridade</label>
            <select
              v-model="filters.priority"
              class="form-control"
              @change="getProduct"
            >
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Descrição</th>
                  <th scope="col">Código</th>
                  <th scope="col">Empresa</th>
                  <th scope="col">Estoque Atual</th>
                  <th scope="col">Estoque Minimo</th>
                  <th scope="col">Prioridade</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in products" :key="product.id">
                  <th scope="row">{{ product.id }}</th>
                  <td>{{ product.description }}</td>
                  <td>{{ product.code }}</td>
                  <td>{{ product.company.company_name }}</td>
                  <td>
                    <span :class="product.stock_status">
                      {{ product.stock }}</span
                    >
                  </td>
                  <td>{{ product.minimum_stock }}</td>
                  <td>{{ product.priority }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-4 mt-3 centered">
            <pagination
              v-if="loadingPagination"
              :records="records"
              v-model="page"
              :per-page="per_page"
              @paginate="getProduct"
              :options="{
                texts: {
                  count: 'Mostrando {from} de {count} de registros',
                },
              }"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      products: [],
      companies: [],
      filters: {
        company_id: null,
        code: null,
        description: null,
        grain: null,
        priority: null,
      },
      records: 0,
      per_page: 50,
      page: 1,
      loadingPagination: false,
    };
  },
  methods: {
    debounce: _.debounce(function () {
      this.getProduct();
    }, 500),
    getProduct() {
      const self = this;
      const api = self.$store.state.api + "product/get-panel?page=" + self.page;

      self.$loading(true);
      self.loadingPagination = false;

      axios
        .post(api, self.filters)
        .then((response) => {
          self.products = response.data.data;
          self.page = response.data.current_page;
          self.per_page = response.data.per_page;
          self.records = response.data.total;
          self.loadingPagination = true;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.loadingPagination = true;
        });
    },
    getCompanies() {
      const self = this;
      const api = self.$store.state.api + "companies?paginated=false";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.companies = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
  },
  mounted() {
    const self = this;
    self.getProduct();
    self.getCompanies();
  },
};
</script>

<style scoped>
.button-operation {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border: 2px #000000 solid;
  cursor: pointer;
  border-radius: 5px;
  font-size: 22px;
}

.output {
  background: rgba(156, 9, 9, 0.658);
  color: #fff;
  border-color: rgb(156, 9, 9);
}

.output-active {
  background: rgb(156, 9, 9);
  color: #fff;
  border-color: rgb(156, 9, 9);
  font-weight: bold;
}

.input {
  background: rgba(12, 99, 0, 0.658);
  color: #fff;
  border-color: rgb(12, 99, 0);
}

.input-active {
  background: rgb(12, 99, 0);
  color: #fff;
  border-color: rgb(12, 99, 0);
  font-weight: bold;
}
</style>
